const messages = {
  pl: {
    shoppingCart: {
      actions: {
        addToCart: 'Dodaj do koszyka',
        buy: 'Kup',
        order: 'Zamówienie',
        remove: 'Usunąć',
      },
      cart: {
        total: {
          label: 'Razem',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Wartość produktów',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Koszt wysyłki',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Razem do zapłaty',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Podatky razem',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Zresetuj swój koszyk',
      },
      messages: {
        confirmResetCart: 'Czy na pewno chcesz usunąć zawartość koszyka?',
        giftClaim: 'Możesz otrzymać {quantity} sztuk {productName} w prezencie.',
        cartManipulations: {
          allRemoved: 'Wszystkie produkty zostały usunięte z koszyka.',
          itemAdded: '{number, plural, one {element dodany do koszyka} other {elementy dodane do koszyka}}.',
          itemRemoved: '{number, plural, one {element usunięty z koszyka} other {elementy usunięte z koszyka}}.',
          quantityChaged: 'Ilość zmieniona',
        },
      },
      components: {
        appBartActionCart: 'Koszyk',
        quantityField: {
          states: {
            quantityRounded: 'Ilość została zaokrąglona do najbliższego rozmiaru opakowania.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Twój koszyk jest pusty',
        },
        cartAfterAddModal: {
          continueShopping: 'Kontynuować zakupy',
          goToCart: 'Przejdź do koszyka',
          title: 'Produkty zostały dodane do koszyka',
          pcs: '',
        },
      },
    },
  },
};
export default messages;
