const messages = {
  pl: {
    app: {
      about: 'O aplikacji',
      actions: {
        add: 'Dodaj  ',
        addNamed: 'Dodaj {name}',
        remove: 'Usuń',
        removeNamed: 'Usuń {name}',
        removeConfirmation: 'Czy na pewno chcesz usunąć?',
        more: 'Więcej',
        less: 'Mniej',
        download: 'Pobierz',
        save: 'Zapisz',
        cancel: 'Odwołaj to',
        close: 'Zamknij',
        moreFormFields: 'Więcej pól',
        export: 'Eksport',
        edit: 'Edytuj',
        createdSuccessMessage: 'Utworzono pomyślnie.',
        savedSuccessMessage: 'Zapisano pomyślnie.',
        deletedSuccessMessage: 'Skutecznie usunięte.',
        detail: 'Szczegół',
        next: 'Następny',
        back: 'Powrot',
        start: 'Zacznij',
        whatNext: 'Co jest następne...',
        confirm: 'Potwierdź',
        confirmRemove: 'Czy na pewno chcesz usunąć?',
        search: 'Szukaj',
        userProfile: 'Profil użytkownika',
        tenantProfile: 'Twój profil CRM',
        imports: 'Import',
        fileImports: 'Importuj pliki',
        settings: 'Ustawienia',
        logout: 'Wyloguj',
        login: 'Zaloguj się do Sprinx CRM',
        filterList: 'Sortuj lista',
        excel: 'Przewyższać',
        refresh: 'Resetowanie',
        clear: 'Sprzątać',
        agree: 'zgadzam się',
        disagree: 'nie zgadzam się',
        makeCopy: 'Zrób kopię',
        fullscreen: 'Pokaż na pełnym ekranie',
        fullscreenClose: 'Zamknij wyświetlanie pełnoekranowe',
        copy: 'Kopiuj',
      },
      messages: {
        confirmRemove: 'Czy na pewno chcesz usunąć ten produkt?',
        errorOccurred: 'Wystąpił błąd',
        createdSuccess: 'Stworzenie zakończyło się sukcesem.',
        savedSuccess: 'Ratowanie powiodło się.',
        deletedSuccess: 'Usunięcie powiodło się.',
        uploadSuccess: 'Plik został pomyślnie przesłany.',
        uploadError: 'Błąd podczas pobierania pliku.',
        uploadInProgress: 'Ładowanie...',
        uploadedFiles:
          '{number, plural, one {1 plik przesłany} few {{number} przesłanych plików} other {{number} pliki zostały przesłane}}',
      },
      error: 'Błąd!',
      validations: {
        isRequired: 'Pole {field} jest obowiązkowe.',
        minLengthIsRequired:
          'Wymagane są co najmniej {number, plural, one {1 pozycja} few {{number} pozycje} other {{number} pozycje}}.',
        minValue: 'Maksymalna dozwolona wartość to {value}.',
        maxValue: 'Maksymalna dozwolona wartość to {value}.',
        passwordMatchError: 'Błąd dopasowania',
        invalidEmail: 'E-mail jest nieprawidłowy.',
        invalidPhoneNumber: "Numer telefonu musi być w prawidłowym formacie międzynarodowym! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Nieprawidłowy numer',
        invalidUrl: 'Nieprawidłowy adres URL.',
        positive: 'Liczba musi mieć wartość dodatnią.',
        negative: 'Liczba musi mieć wartość ujemną.',
      },
      login: {
        title: 'Zaloguj się do Sprinx CRM',
        username: 'Nazwa użytkownika',
        password: 'Hasło',
        loginButton: 'Zaloguj',
        error: 'Błąd logowania. Nie znaleziono nazwy użytkownika lub hasła',
        pleaseRelogin: 'Wygląda na to, że nie jesteś zalogowany. Spróbuj się zalogować ponownie.',
      },
      days: '{count, plural, one {1 den} few {{formattedCount} dni} other {{formattedCount} dni}}',
      overdue: 'zamuda {count, plural, one {1 den} few {{formattedCount} dni} other {{formattedCount} dni}}',
      newItem: 'Nowy',
      anonymizing: {
        confirm: {
          title: 'Potwierdź anonimizację',
          content: 'Czy na pewno chcesz anonimizować dane osobowe?',
        },
        button: 'Anonimizacja danych osobowych',
      },
      appMenu: {
        discover: 'Dowiadywać się',
      },
      missingType: 'Najpierw określ {typeName} w administracji aplikacji.',
      missingTypeForEntityType: 'Najpierw zdefiniuj {typeName} dla {forEntityTypeName} w administracji aplikacji.',
      emptyScoringBlog: 'Moduły których zawartość lub ustawienia są wyświetlane na tej stronie, nie są aktywowane.',
    },
    components: {
      buttons: {
        showOnMap: 'Pokaż na mapie',
      },
      filters: {
        filterButtonSubmit: 'Szukaj',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Dodaj warunek',
        resetFilter: 'Zresetuj filtr',
        operators: {
          contains: {
            0: 'zawiera',
            _: 'zawiera',
          },
          containsexact: {
            0: 'zawiera dokładnie',
            _: 'zawiera dokładnie',
          },
          notcontains: {
            0: 'nie zawiera',
            _: 'nie zawiera',
          },
          betweennumber: {
            0: 'z',
            1: 'robić',
            _: 'wewnątrz',
          },
          betweendate: {
            0: 'z',
            1: 'robić',
            _: 'wewnątrz',
          },
          equal: {
            0: 'równy',
            _: 'równy',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Razem pozycje: {number}',
        nextPage: 'Lista {number} produktów',
        emptyMessage: 'Nie znaleziono produktów.',
      },
      selects: {
        emptyOption: 'Pusty',
        emptyMessage: 'Nie znaleziono produktów.',
      },
      tables: {
        labelRowsPerPage: 'Linia na stronie',
        sortBy: 'Sortuj według {field}',
        recordsChanged: 'Niektóre rekordy zostały dodane lub zmienione.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Nieregularny format daty.',
        invalidDate: 'Niepoprawny',
        maxDateMessage: 'Data nie może być większa niż data maksymalna',
        minDateMessage: 'Data nie może być wcześniejsza niż data minimalna',
        today: 'Dziś',
      },
      detailNavigation: {
        scoringblog: 'Blog o punktacji',
        card: 'Karta',
        relations: 'Być',
      },
      manipulationInfo: {
        created: 'Utworzony',
        updated: 'Zmieniono',
        removed: 'Usunięto',
      },
    },
  },
};
export default messages;
