const messages = {
  pl: {
    parameters: {
      yes: 'ORAZ',
    },
    com: {
      phase: {
        label: 'Cechowanie',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Kod produktu',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Producent',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Numer pracy',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Gwarancja',
          months: 'miesiące',
        },
      },
      totalAmount: {
        label: 'Razem',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} więcej',
    },
    orders: {
      actions: {
        all: 'Wszystkie zamówienia',
      },
      myOrdersScoringBlog: {
        title: 'Moje zamówienia',
      },
      ordersList: 'Zamówienia',
      phase: {
        1: 'Otrzymane',
        '01-new': 'Otrzymane',
        10: 'Otrzymane',
        20: 'czekać na wypłatę',
        30: 'Płatny',
        98: 'Zadomowiony',
        99: 'Anulowany',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Numer paczki',
            phase: 'Status',
            paid: 'Płatny',
            total: 'bez VAT',
            totalWithTax: 'Wartość',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Koszyk',
        buyButton: 'Wyślij zamówienie',
        emptyBasket: 'Twój koszyk jest pusty',
        buyThere: 'Robiłem tu zakupy wcześniej',
        login: 'Zaloguj',
        notFill: 'Nie wypełniono szczegółów dostawy',
        fill: 'Proszę uzupełnić',
        change: 'Dane zamawiającego',
        popupButton: 'Dodaj do koszyka',
        payment: {
          title: 'METODA PŁATNOŚCI',
          free: 'Darmowy',
          changePaymentBtn: 'Wybierz płatność',
        },
        shipping: {
          title: 'SPOSÓB DOSTAWY',
          free: 'Darmowy',
          changeShippingBtn: 'Wybierz sposób dostawy',
          popup: {
            title: 'Informacja',
            text: 'Kurier {service} dostawa do domu',
          },
        },
      },
      actions: {
        all: 'Wszystkie kosze',
      },
      myBasketsScoringBlog: {
        title: 'Zapisane koszyki',
      },
      cartSummaryParameters: {
        caption: 'Kod produktu',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Opis',
      parameters: 'Parametry',
      package: 'Dołączone akcesoria',
      fotoAndVideo: 'Obraz i wideo',
      alternatives: 'Alternatywy',
      alternativesText: 'Brak alternatyw',
      questions: 'Pytania',
      productDescriptionNotAvailable: 'Brak opisu produktu',
    },
    newsletter: {
      phone: '+386 1 82 82 054',
      subscribe: {
        title: 'Nie przegap okazji! Zapisz się na newsletter i bądź na bieżąco!',
        inputText: 'E-mail',
        button: 'Zapisz',
      },
    },
    globalNavigation: {
      home: 'Strona główna',
      shopping: 'Kontynuuj zakupy',
      orders: 'Zamówienia',
      shoppingLists: 'Listy zakupów',
      articles: 'Artykuły i poradniki',
    },
    homePageWhy: {
      0: {
        title: 'Bezpieczne zakupy w 100%',
        content:
          'Autoryzowany system płatności 3D Secure gwarantujący optymalne bezpieczeństwo podczas zakupów online.',
      },
      1: {
        title: 'Zadowolony lub zwrócony',
        content: 'Masz prawo zmienić zdanie. Skorzystaj z naszej usługi szybkiego zwrotu pieniędzy w ciągu 14 dni.',
      },
      2: {
        title: 'Magazyn w czasie rzeczywistym',
        content: 'Towar we własnym magazynie. Mamy 98% całego asortymentu w magazynie do natychmiastowej wysyłki.',
      },
      3: {
        title: 'Darmowa dostawa',
        content:
          'Przy zakupie aparatów cyfrowych, obiektywów i innych wybranych produktów korzystasz z DARMOWEJ dostawy.',
      },
      title: 'CO NAS WYRÓŻNIA?',
    },
    startPage: {
      title: 'Strona główna',
      gotoShopping: 'Kontynuuj zakupy',
    },
    shoppingCart: {
      actions: {
        buy: 'DODAJ DO KOSZYKA',
      },
      buyBtn: 'DODAJ DO KOSZYKA',
      alternatives: 'ALTERNATYWY',
      shipping: {
        changePoint: 'Zmienić',
      },
      agreements: {
        termsRequired: 'Musisz wyrazić zgodę na warunki biznesowe.',
      },
      validation: {
        someProductNotAvailable: 'Niektóre pozycje nie są dostępne w wystarczających ilościach.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Kod produktu,',
    },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Trafność',
        name: 'Nazwa: A-Z',
        priceAsc: 'Cena: od najwyższej',
        priceDesc: 'Cena: od najniższej',
      },
    },
    orderStatuses: {
      storno: 'Anulowanie',
      synchronized: 'Wysłano',
      waiting: 'Czekam na synchronizację',
    },
    orderPreview: {
      items: 'Produkty',
    },
    cartRegisterForm: {
      title: 'Formularz rejestracyjny',
    },
    cartPayment: {
      title: 'METODA PŁATNOŚCI',
    },
    cartPreSummaryPage: {
      title: 'Podsumowanie koszyka',
      actions: {
        nextStep: 'Zgadzam się i kontynuuję',
      },
    },
    cartCompanyForm: {
      description: 'Wypełnij jeśli kupujesz towary dla firmy',
    },
    cartDeliveryForm: {
      description: 'Wypełnij jeśli adres wysyłki jest inny niż adres do faktury',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Kiedy otrzymam zamówiony towar?',
    },
    productDetailStockInfo: {
      available: 'Produkt jest w naszym magazynie dystrybucyjnym.',
      external: 'Produkt jest już w drodze do naszego magazynu',
      notAvailable: 'Produkt jest niedostępny',
      labels: {
        free: 'DARMOWA DOSTAWA',
        showroom: 'WYŚWIETLANE W SALONIE',
      },
    },
    productGifts: {
      btnName: 'PREZENT',
      giftValue: 'W wartości',
    },
    homepage: {
      productOfMonth: 'Sprawdź, co dla Ciebie mamy',
      catalogFavorite: 'Strefa okazji',
      hotTips: 'Hity dnia',
      recommended: 'Wybrane dla Ciebie',
    },
    productQuestion: {
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'E-mail',
      subject: '',
      question: 'Pytanie',
      gdpr: 'Wysyłając pytanie wyrażam zgodę na przetwarzanie danych osobowych w celu uzyskania odpowiedzi na zadane pytanie.',
      gdprLink: 'Link do RODO',
      button: 'Zadaj pytanie',
      subjectContent: 'Pytanie o produkt: {product}, kod: {sku}',
      title: 'Musisz wyrazić zgodę na przetwarzanie danych osobowych.',
      success: 'Pytanie zostało wysłane',
    },
    lastVisitedProducts: 'Ostatnio odwiedzane pozycje',
    productAlternativeProducts: 'Alternatywny',
    productAccessoryProducts: 'Akcesoria',
    productReplacementProducts: 'Akcesoria',
    productAccessories: 'Akcesoria',
    productReplacements: 'Zastąpienie',
    appProductDetailTabs: {
      description: 'Opis',
      parameters: 'Parametry',
      package: 'Dołączone akcesoria',
      query: 'Pytanie',
      media: 'Obraz i wideo',
      alternatives: 'Alternatywny',
      accessories: 'Akcesoria',
      replacements: 'Akcesoria',
      form: {
        enum: '!!!! WYLICZ WARTOŚĆ',
      },
    },
    cartSummaryPage: {
      title: 'Podsumowanie',
      actions: {
        continueShopping: 'Kontynuować zakupy',
        saveOrder: 'Złoż zamówienie',
      },
      expectedDeliveryDate: {
        label: 'Wymagana data dostawy',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Uwaga dla dostawcy',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Bramki płatności',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Moje konto',
      content: 'Zawartość',
      contactInfo: {
        newsletter: {
          title: 'Biuletyn Informacyjny',
          content: 'Chcę otrzymywać wiadomości Digiexpert.pl',
        },
        title: 'szczegóły kontaktu',
      },
      orders: {
        title: 'Historia zamówień',
      },
      invoices: {
        title: 'Moje konta',
      },
      myOrders: 'Moje zamówienia',
      myInvoices: 'Moje konta',
      logout: 'Wyloguj',
      login: 'Konto',
      favorites: {
        title: 'Ulubione',
      },
      changePassword: {
        title: 'Zmiana hasła',
        fields: {
          login: 'Login (nie można zmienić)',
          originalPassword: 'Oryginalne hasło',
          newPassword: 'Nowe hasło',
          confirmNewPassword: 'Potwierdź hasło',
        },
        button: 'Zapisz',
      },
    },
    orderSavedThankPage: {
      title: 'Dziękujemy za zamówienie {orderNumber}',
      thankMessage: {
        email: 'info@digiexpert.pl',
        phoneNumber: '',
        template: '',
      },
      order: 'Numer zamówienia:',
      payment: 'Płatność dnia:',
      cancelled: 'Anulowane',
      accepted: 'Otrzymane',
      pending: 'W oczekiwaniu',
      paid: 'Płatny',
      actions: {
        back: 'Powrót do strony głównej',
        detail: 'Podsumowanie zamowienia',
        continue: 'Kontynuować zakupy',
      },
      decline: 'Odrzucony',
    },
    shoppingLists: {
      title: 'Listy zakupów',
      actions: {
        saveAsShoppingList: 'Zapisz jako listę zakupów',
        importShoppingListToCart: 'Import',
      },
    },
    searchResultPage: {
      search: 'Szukaj',
      results: 'wyniki wyszukiwania',
    },
    homePageArticles: {
      title: 'Nowicjusz',
      button: 'Zobacz wszystkie',
    },
    loginPage: {
      title: 'Zaloguj się ',
      forgotPassword: 'Zresetowanie hasła',
      email: 'E-mail',
      sendEmail: 'Wyślij e-mail',
      info: 'Otrzymasz e-mail z linkiem do zmiany hasła',
      password: 'Hasło',
      notSame: 'Proszę wprowadzić identyczną wartość',
      newPassword: 'Nowe hasło',
      confirmPassword: 'Potwórz hasło',
      changePassword: 'Zmiana hasła',
    },
    productPrice: {
      withoutTax: 'bez VAT',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Zamów {today}, a wyślemy w ciągu {minDays}-{maxDays} dni.',
        today: 'Zamów dzisiaj przed {time}, a wyślemy dzisiaj.',
        todayWord: 'Dziś',
        tomorrow: 'Zamów {today}, a wyślemy jutro.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Zamów {today}, a my wyślemy {day}.',
          1: 'Poniedziałek',
          2: 'Wtorek',
          3: 'Środa',
          4: 'Czwartek',
          5: 'Piątek',
          6: 'Sobota',
          0: 'Niedziela',
        },
      },
      allDescription: 'OPIS PRODUKTU',
      demoProduct: {
        text: 'KUP TANIEJ za:',
      },
      productNote: {
        title: 'KUP TANIEJ za: 2150,00 €',
        note: '',
      },
      adornments: {
        notForSale: 'W szczególności nie na sprzedaż',
        usedProduct: 'Outlet',
        freeGift: 'Darmowy prezent',
        freeShipping: 'Darmowa dostawa',
        eol: 'Wyprzedaż zamknięta',
        demo: 'Produkt demonstracyjny',
        sale: 'Zniżka',
      },
      disponibility: {
        primaryLessThan: 'W magazynie mniej niż {count} sztuk',
        primaryMoreThan: 'W magazynie {count, plural, zero {jedna sztuka} other {{count} i więcej sztuk}}',
        secondaryLessThan: 'W magazynie zewnętrznym mniej niż {count} sztuk',
        secondaryMoreThan:
          'W magazynie zewnętrznym {count, plural,  zero {jedna sztuka} other {{count} i więcej sztuk}}',
        external: 'Produkt jest już w drodze do naszego magazynu',
        unavailable: 'Produkt niedostępny',
        showroom: '(wyświetlane w salonie)',
        soldout: 'Produkt wyprzedany',
        'on-way': {
          d: '',
          m: 'W drodze w {from, plural, one {minuta} two {minuta} other {minuta}}',
          M: 'W drodze w {from, plural, one {miesiąc} two {miesiące} few {miesiące} other {miesiące}}',
          dRange: 'W drodze w {to, plural, few {{from}-{to} dni} other {{from}-{to} dni}}',
          mRange: 'W drodze {from}-{to, plural, one {niż} few {who} other {who}}',
          MRange: 'W drodze w {from}-{to, plural, one {miesiąc} two {miesiące} few {miesiące} other {miesiące}}',
          date: '{datum}',
        },
        time: 'W drodze do {count, plural, zero {jeden kawałek} other {{count} i więcej sztuk}}',
      },
    },
    comparePage: {
      title: 'Porównanie',
      removeButton: 'Usuń produkt',
      nothing: 'Brak produktów do porównania',
    },
    filter: {
      reset: 'Zresetuj filtr',
      search: 'Szukaj',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Warunki zamówienia',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Dodaj do koszyka',
      },
    },
    invoiceBlogPreview: {
      title: 'Moje konta',
      button: 'Wszystkie konta',
    },
    userProfilePage: {
      logout: 'Wymeldować się',
      setting: 'Ustawienia',
      passwordChange: 'Zmiana hasła',
    },
    appBarActionRegion: {
      changeRegion: 'Zmień język',
      label: 'Zamknij',
    },
    subCategories: {
      title: 'Podkategorie',
    },
    aboutShoppingPage: {
      title: 'Wszystko o zakupach',
      contact: 'Kontakt',
      electronicClosureOfRevenue: 'Elektroniczna ewidencja sprzedaży',
      informationDuty: 'Obowiązek informacyjny',
      processingOfPersonalData: 'Przetwarzania danych osobowych',
      cashback: 'Cashback',
      delivery: 'Dostawa',
      availability: 'Dostępność',
      methodOfPayment: 'Sposóby płatności',
      termsAndConditions: 'Regulamin',
      termsOfAppeal: 'Reklamacje',
      contractwWithdrawal: 'Odstąpienie od umowy',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Twój numer podatkowy został zweryfikowany, ceny nie zawierają podatku VAT.',
        viesNotVerified: 'Twój numer podatkowy nie został zweryfikowany lub nie kwalifikujesz się do zakupu bez VAT.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'DANE PŁATNIKA',
      pleaseComplete: 'PROSZĘ WYPEŁNIĆ',
      closeButton: 'Zamknij',
    },
    cartPromoCodes: {
      title: 'ZASTOSUJ KOD PROMOCYJNY',
      textFieldPlaceholder: 'KOD PROMOCYJNY',
      textFieldHelperText: 'Można zastosować tylko jeden kod promocyjny na zamówienie',
      applyPromoCodeButton: 'ZASTOSUJ',
    },
    cookiesBar: {
      agreeBtn: 'Zgadzam się',
      title: 'POWIADOMIENIE O PLIKACH COOKIES NA NASZEJ STRONIE',
      text: 'Nasza firma używa własnych plików cookie (tzw. niezbędnych plików cookie) oraz plików cookie stron trzecich na tej stronie internetowej. Na podstawie tych plików i zainteresowań dostosowujemy strony internetowe do oczekiwań klientów, optymalizujemy nasze oferty i zapobiegamy ryzyku oszustwa. Bez niektórych niezbędnych plików cookie witryna nie może działać poprawnie. Klikając przycisk „Akceptuję wszystko” wyrażasz zgodę na ich użycie, w tym informacje o zachowaniu serwisu. W każdej chwili możesz odwołać swoją zgodę.',
    },
    cartFormLogin: {
      btnSend: 'Wyślij',
      button: 'Zaloguj się',
      title: 'Aplikacja',
      forgottenPasswordBtn: 'Nie pamiętasz hasła?',
      text1: 'Na podany adres e-mail wyślemy jednorazowe hasło.',
      text2: 'Po zalogowaniu ustaw nowe hasło.',
      error: 'Podane hasło lub email są nieprawidłowe',
    },
    cartSummartSection: {
      title: 'PODSUMOWANIE ZAMOWIENIA',
    },
    appCartSummarySubjectsList: {
      title: 'PRODUKT',
      text: 'NAZWA',
    },
    newsletterText: {
      text1: 'Potrzebujesz pomocy?',
      text2: 'Można się z nami kontaktować w każdy dzień roboczy w godzinach 8:00 - 16:00.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(bez VAT)',
      priceLabel: 'CENA JEDNOSTKOWA',
      amount: 'ILOŚĆ',
      totalPriceLabel: 'WARTOŚĆ',
      totalPrice: 'Razem',
      submitButton: 'ZŁÓŻ ZAMÓWIENIE',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Bezpłatnie',
      amountTitle: '',
    },
    sxCartNotes: {
      note: 'Nie służy do wskazania daty dostawy',
      addNote: 'Dodaj notatkę',
    },
    productDetail: {
      promoCode: {
        title: 'z kodem promocyjnym:',
        validTo: 'ważny do:',
      },
      productSendLink: {
        copyLinkBtn: 'Skopiuj link',
        sendLinkByEmail: 'Wyślij link e-mailem',
      },
      productDetailBarInformation: {
        addToFavourites: 'Dodaj do ulubionych',
        compareProduct: 'Porównaj',
        sendLink: 'Udostępnij',
        serviceList: 'Usługi',
        questions: 'Pytania',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'KUPUJĘ JAKO FIRMA',
        otherDeliveryInfo: 'INNY ADRES DO WYSYŁKI',
        otherDeliveryInfoAlert: 'Podatnik VAT nie może używać innego adresu do doręczeń.',
      },
      companyNameLabel: 'Firma',
      firstNameLabel: 'Imię',
      lastNameLabel: 'Nazwisko',
      streetLabel: 'Ulica oraz numer mieszkania',
      cityLabel: 'Miejscowość',
      zipLabel: 'Kod pocztowy',
      companyIDLabel: 'NIP',
      countryLabel: 'Kraj',
    },
    cartFormPersonal: {
      label: 'Dane do wysyłki',
      firstNameLabel: 'Imię',
      lastNameLabel: 'Nazwisko',
      streetLabel: 'Ulica oraz numer mieszkania',
      pscLabel: 'Kod pocztowy',
      cityLabel: 'Miejscowość',
      countryLabel: 'Kraj',
      phoneLabel: 'Numero telefonu',
      emailLabel: 'Adres e-mail',
      passwordLabel: 'Hasło',
      controlPasswordLabel: 'Powtórz hasło',
    },
    cartFormInvoice: {
      labelDesktop: 'DANE PŁATNIKA',
      labelMobile: 'szczegóły kontaktu',
      companyLabel: 'Firma',
      password: 'Hasło',
      confirmPassword: 'Weryfikacja hasła',
      email: 'Adres e-mail',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      countryLabel: 'Kraj',
      phone: 'Numer telefonu',
      phoneError: 'Miejscowość',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Numer domu',
      zipLabel: 'Kod pocztowy',
      icoLabel: 'Nie',
      dicLabel: 'NIP',
      country: 'Kraj',
      cityLabel: 'Miejscowość',
      registration: 'Załóż konto',
      registrationNote: 'Otwórz konto i możesz śledzić swoje zamówienia przesyłki i płatności.',
      button: 'Zapisz',
    },
    page404: {
      title: 'Nie mogliśmy znaleźć takiej strony',
      subTitle: 'Powrót do strony głównej.',
      button: 'Powrót do strony głównej.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Sortuj według:',
      byName: 'A-Z',
      byPrice: 'Cena',
      onlyStock: 'Towar w magazynie',
      resetFilter: 'Usuń',
      displayCountOfProducts: 'Produkty:',
      search: 'Szukaj',
      clear: 'Usuń',
      ok: 'ZASTOSUJ FILTRY',
    },
    priceRangeSliderField: {
      title: 'Cena',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtruj według',
    },
    productListMultipleFilterMobile: {
      title: 'FILTR',
    },
    cartCompany: {
      companyName: 'Firma',
      regNo: 'Regon',
      taxNo: 'NIP',
      taxNoPlaceholder: 'PL00000001',
      warningMessage: {
        warning: 'Ważny.',
        message: 'W przypadku dostawy na adres inny niż adres firmy, zamówienie będzie opodatkowane podatkiem VAT.',
      },
    },
    searchResultsPage: {
      match: 'Znaleźliśmy {resultCountFormatted} wyników dla {searchText}.',
      nomatch: 'Nie znaleziono wyników dla {searchText}.',
      matchingCategories: 'Znalezione kategorie',
      button: 'Powrót do strony głównej.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Wybrane {total} pozycje',
    },
    cartDelivery: {
      firstName: 'Imię',
      lastName: 'Nazwisko',
      companyName: 'Firma',
      streetLabel: 'Ulica',
      streetNumberLabel: 'Numer domu',
      zipLabel: 'Kod pocztowy',
      country: 'Kraj',
      cityLabel: 'Miejscowość',
    },
    cartNotes: {
      shippingLabel: 'Dodaj notatkę dla kuriera',
      shippingNote: 'Notatka',
      sellerLabel: 'Dodaj notatkę dla sklepu',
      sellerNote: 'Notatka',
      loginNote: 'Jeśli masz już u nas konto formularz zostanie wypełniony automatycznie po rejestracji.',
    },
    cartCountryShipping: {
      title: 'KRAJ DOSTAWY',
      label: 'Wybór kraju wpływa na sposób płatności i wysyłki jak również termin dostawy',
    },
    cartAddress: {
      bllingAddress: 'Adres do faktury',
      deliveryAddress: 'Adres dostawy',
      pickUpPointAddress: 'Adres punktu odbioru',
    },
    cartAgreements: {
      customerVerified:
        'Nie wyrażam zgody na przesyłanie mi ankiety będącej częścią programu „Weryfikacja przez Klientów”, który prowadzony jest w celu pomiaru satysfakcji Klientów i podnoszenia jakości naszych usług.',
    },
    loginDialog: {
      username: 'E-mail',
      password: 'Hasło',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Wprowadzone hasła nie są takie same',
      invalidPostalCode: 'Podany kod pocztowy ma nieprawidłowy format',
      usernameIsNotFree: 'Użytkownik o tej nazwie jest już zarejestrowany.',
      emailEmpty: 'E-mail jest poprawny!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Dodaj nowe konto',
        all: 'Wszystkie konta',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Numer',
            invoiceDate: 'data wystawienia faktury',
            dueDate: 'W dniu',
            total: 'bez VAT',
            totalWithTax: 'Wartość',
            billingAddress: 'Adres do faktury',
            orderNumber: 'Numer zamówienia',
            unpaidAmount: 'Niezapłacona kwota',
          },
          filter: {
            number: 'Numer',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Wiersze konta',
          columns: {
            priceUnit: 'Cena',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Wartość',
      tax: 'Kwota VAT',
      withoutTax: 'Cena bez VAT',
    },
    compare: {
      addToCompare: 'Produkt został dodany do porównywarki',
      removeToCompare: 'Produkt został usunięty z porównywarki',
      price: 'Cena',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Produkt został dodany do ulubionych',
      removeFromFavorite: 'Produkt został usunięty z ulubionych',
    },
    registration: {
      important: 'UWAGA!',
      note: 'Jednorazowe hasło prześlemy e-mailem, zmień hasło po zalogowaniu.',
      gdprAgreement: 'Musisz wyrazić zgodę na przetwarzanie danych osobowych',
      submitButton: 'Utwórz konto',
      fail: 'Konto nie zostało utworzone',
      success: 'Konto zostało pomyślnie utworzone',
      title: 'Rejestracja',
      dialog: 'Jeśli nie masz jeszcze u nas konta, możesz się zarejestrować',
      here: 'tutaj',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Szukaj produktów',
    },
    appBarActions: {
      about: 'Wszystko o zakupach',
      aboutHref: '/wszystko-o-zakupach',
      compare: 'Porównanie',
    },
    appBarActionUser: {
      title: 'Zaloguj się',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. - Wszystkie prawa zastrzeżone',
    },
    appArticlesPage: {
      title: 'Artykuły i poradniki',
    },
    errorPage: {
      title: 'Coś jest nie tak.',
      button: 'Strona główna',
    },
    cartSummary: {
      withoutTax: 'bez VAT',
    },
    renewPasswordForm: {
      success: 'Nowe hasło zostało wysłane na Twój adres e-mail.',
      emailNotFound: 'Nie znaleziono adresu e-mail!',
      error: 'Coś jest nie tak',
    },
    cartSubjectListItemQuantityWarning: 'Maksymalna ilość zamówienia to {count} produktów',
  },
};
export default messages;
