const messages = {
  pl: {
    orders: {
      actions: {
        addNewOrder: 'Dodaj zamówienie',
        all: 'Wszystkie zamówienia',
      },
      listPage: {
        title: 'Zamówienia',
        table: {
          filter: {
            number: 'Numer',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Data zamówienia',
            number: 'Numer',
            offer: 'Oferta',
            opportunity: 'Możliwość',
            owner: 'Właściciel',
            state: 'Status',
            total: 'Razem',
            totalUserCurrency: 'Razem w {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nowe zamówienie',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Całkowita prowizja',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Waluta',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Data zamówienia',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Numer',
          helperText: 'Pozostaw puste aby automatycznie uzupełniać numer.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Oferta',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Możliwość',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Właściciel',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Marża handlowa',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Sprawa',
          discount: {
            label: 'Zniżka',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Zamawiać',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Wielkość paczki',
          },
          listPrice: {
            label: 'Cena w cenniku',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Cena sprzedaży',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produkt',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Kwota',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Marża handlowa',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Razem dla produktu',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Jednostka',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Razem',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {brak produktów} one {1 produkt} few {{number} produktów} other {{number} pozycje}}',
      },
      myOrdersScoringBlog: {
        title: 'Moje zamówienia',
      },
      ordersList: 'Zamówienia',
    },
  },
};
export default messages;
