const messages = {
  pl: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Koszyk',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametry',
        },
      },
      productAvailability: {
        format: {
          available: 'W magazynie',
          moreThanX: 'Więcej niż {min} sztuk w magazynie',
          exactCount: '{value} sztuk w magazynie',
          notAvailable: 'Niedostępne',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} więcej',
      },
      relatedProducts: {
        title: 'Podobne produkty',
      },
      productAccessories: {
        title: 'Akcesoria',
      },
      productDetailImageGallery: {
        noImage: 'Brak obrazka',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kiedy otrzymam towar?',
      },
    },
  },
};
export default messages;
