const messages = {
  pl: {
    reactMuiTables: {
      selected: 'wybrany',
      filterhide: 'Ukryj filtr',
      filtershow: 'Pokaż filtr',
      refresh: 'odśwież',
      filter: {
        actions: {
          search: 'Szukaj',
          resetFilter: 'Zresetuj filtr',
          addCondition: 'Dodaj warunek',
        },
      },
      standardOperators: {
        equals: 'prosto w górę',
        contains: 'zawiera',
        between: {
          label: 'pomiędzy',
          from: 'odśwież',
          to: 'do',
        },
        oneOf: 'jeden z',
      },
      labelRowsPerPage: 'Wierszy na stronę,',
    },
  },
};
export default messages;
