const messages = {
  pl: {
    sprinxFormsValidation: {
      required: 'To pole jest wymagane!',
      string: 'Musi być ciągiem tekstowym!',
      stringEmpty: 'Ne sme biti puste',
      stringMin: 'Długość musi być większa lub równa {expected} znakom!',
      stringMax: 'Długość musi być mniejsza lub równa {expected} znakom!',
      stringLength: 'Długość musi wynosić {expected} znaków!',
      stringPattern: 'Nie pasuje do wymaganego wzoru!',
      stringContains: 'Musisz dołączyć tekst „{expected}”!',
      stringEnum: 'Nie pasuje do żadnej z dozwolonych wartości!',
      number: 'To musi być liczba!',
      numberMin: 'Musi być większy lub równy {expected}!',
      numberMax: 'Musi być mniejszy lub równy {expected}!',
      numberEqual: 'Musi być taki sam jak {expected}!',
      numberNotEqual: 'Nie może być taki sam jak {expected}!',
      numberInteger: 'Musi być liczbą całkowitą!',
      numberPositive: 'Liczba musi mieć wartość dodatnią!',
      numberNegative: 'Liczba musi mieć wartość ujemną.',
      array: 'To musi być lista!',
      arrayEmpty: 'Nie musi to być pusta lista!',
      arrayMin:
        'Musi być co najmniej {number, plural, one {1 produkt} few {{number} produktów} other {{number} pozycje}}!',
      arrayMax:
        'Nie może zawierać więcej niż {number, plural, one {1 produkt} few {{number} produktów} other {{number} produktów}}!',
      arrayLength:
        'Musi zawierać dokładnie {expected, plural, one {1 produkt} few {{number} produktów} other {{number} produktów}}!',
      arrayContains: 'Musi zawierać produkt „{expected}”!',
      arrayEnum: "Wartość '{expected}' nie pasuje do żadnej z dozwolonych wartości!",
      boolean: 'Musi być „tak”, ale „nie”!',
      function: 'To musi być funkcja!',
      date: 'To musi być data!',
      dateMin: 'Musi być większy lub równy {expected}!',
      dateMax: 'Musi być mniejsza lub równa {expected}!',
      forbidden: 'Pole jest zabronione!',
      email: 'E-mail jest poprawny!',
      url: 'Nieprawidłowy adres URL!',
      object: 'Musi być typu Object!',
      arrayReducedEquals: 'Sumaryczna wartość nie jest zgodna z {expected}!',
      phoneNumber: "Numer telefonu musi być w prawidłowym formacie międzynarodowym! '+ XXX XXX XXX XXX '!",
      dateGt: 'Data musi być późniejsza niż {expected}!',
      dateGte: 'Data musi być większa lub równa {expected}!',
      dateLt: 'Data musi być mniejsza niż {expected}!',
      dateLte: 'Data musi być mniejsza lub równa {expected}!',
    },
  },
};
export default messages;
